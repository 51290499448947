<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Newsletter </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Template</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="outline-primary" class="mr-2" @click="$router.push('/newsletter/template/editor')">
            Crea template da Editor
          </b-button>
          <b-button variant="primary" @click="$router.push('/newsletter/template/create')"> Crea template auto </b-button>
        </div>
      </template>

      <div class="mt-2">
        <h3 class="text-primary-light">Template di default per Newsletter</h3>

        <b-row class="mt-2">
          <b-col md="3" v-for="template in templates" :key="template.id_layout">
            <b-card>
              <div
                class="border border-primary-light rounded-lg"
                style="height: 200px"
              ></div>

              <p class="my-1 mb-3">{{template.layout_name}}</p>

              <div class="d-flex justify-content-between align-items-center">
                <feather-icon class="cursor-pointer" icon="EditIcon" size="25" v-b-tooltip.hover.top.v-primary-light="'Modifica'"/>
                <feather-icon class="cursor-pointer" icon="Edit3Icon" size="25" v-b-tooltip.hover.top.v-primary-light="'Rinomina'"/>
                <feather-icon class="cursor-pointer" icon="SendIcon" size="25" v-b-tooltip.hover.top.v-primary-light="'Invia mail di test'"/>
                <feather-icon class="cursor-pointer" icon="LayersIcon" size="25" v-b-tooltip.hover.top.v-primary-light="'Duplica'"/>
                <feather-icon class="cursor-pointer text-danger" icon="Trash2Icon"  size="25" @click="deleteTemplate" v-b-tooltip.hover.top.v-danger="'Elimina'"/>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  VBTooltip,
} from "bootstrap-vue";

import {Requests} from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
  },

  directives: {
      'b-tooltip': VBTooltip,
  },

  data: () => ({
    templates: []
  }),

  created() {
    this.getTemplatesList();
  },

  methods: {

    async getTemplatesList() {
      this.loading = true;

      try {
        const response = await Requests.getTemplatesList();
        this.templates = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    deleteTemplate(id) {
      this.$swal.fire({
        title: "Elimina Template",
        text: "L'azione è irreversibile. Vuoi procedere?",
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        customClass: {
            title: "text-primary-light",
            confirmButton: 'btn btn-danger mr-2',
            cancelButton: 'btn btn-secondary'
        }
      })
    },
    
  }
};
</script>
